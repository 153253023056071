import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('SubSection', {params});
    }, 
    show(id) {
        return Service().get('SubSection/show/'+id);
    },    
    create(params) {
        return Service().post('SubSection/create', params);
    },
    update(id, params) {
        return Service().post('SubSection/update/'+id, params);
    },
    delete(id) {
        return Service().post('SubSection/delete/'+id);
    }
}